
.app-file-field {
  &.v-text-field--outlined {
    .v-input__slot {
      fieldset {
        border-style: dashed;
        border-width: 1.5px;
      }
    }
  }

  &.v-input--is-focused, &.error--text {
    .v-input__slot {
      .v-input__prepend-inner {
        .v-icon {
          caret-color: inherit;
          color: inherit;
        }

        .v-btn {
          caret-color: inherit;
          color: inherit;
        }
      }
    }
  }

  &.theme--light {
    &.v-input {
      caret-color: rgba(0, 0, 0, 0.54);
      color: rgba(0, 0, 0, 0.54);
    }

    .v-icon {
      caret-color: rgba(0, 0, 0, 0.54);
      color: rgba(0, 0, 0, 0.54);
    }

    .v-btn {
      caret-color: rgba(0, 0, 0, 0.54);
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &.theme--dark {
    &.v-input {
      caret-color: #FFFFFF;
      color: #FFFFFF;
    }

    .v-icon {
      caret-color: #FFFFFF;
      color: #FFFFFF;
    }

    .v-btn {
      caret-color: #FFFFFF;
      color: #FFFFFF;
    }
  }

  &.v-input {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .v-icon {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .v-btn {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .v-input__slot {
    padding: 0 !important;
    overflow: hidden;

    .v-input__prepend-inner {
      width: 100%;
      height: var(--height, 100%);
      margin: 0;
      padding: 10px;
      justify-content: center;
      align-items: center;

      .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .file-holder {
          display: flex;
          align-items: center;
        }
      }

      .v-image {
        margin: 0 auto;
        max-width: 100%;
        width: var(--width, 100%);
        height: var(--height, 100%);

        .v-image__image {
          background-size: var(--background-size, contain);
        }
      }

      .image-placeholder {
        margin: 0 auto;
        max-width: 100%;
        width: var(--width, 100%);
        height: var(--height, 70px);
        background-color: #F7F9FD;
        border: 1px dashed #D2D6E2;
      }

      .action {
        position: relative;
        z-index: 1;
      }

      .z-index-top {
        z-index: 1;
      }

      .file-icon {
        font-size: 50px;
      }

      .clear {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        z-index: 1;
      }
    }

    .v-text-field__slot {
      position: absolute !important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;

      input {
        max-height: initial;
        width: 100%;
        height: 100%;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
